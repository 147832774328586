import React, { Fragment, useState } from 'react';
import { Typography, Box, Container, Popover, TextField, IconButton, Tooltip, Button } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';

import { Email } from 'react-frontend-utils' 

/**
 * This component is a popover that allows the user to update the Patron information for a Booking
 * 
 * The user can supply the following props:
 * 
 * isOpen (boolean): True if the popover is open, false otherwise
 * booking (Booking): The Booking object to update
 * closeCallback (function): A callback function that is called when the user closes the popover
 * hasCancelButton (boolean): True if the popover should have a cancel button, false otherwise
 * cancelCallback (function): A callback function that is called when the user clicks the cancel button
 */
export function EditBookingPatronPopover(props) {

    const maxWidth = window.innerWidth * 0.7
    const [email, setEmail] = useState(props.booking.patronEmail)
    const [emailValid, setEmailValid] = useState(true);

    const onEmailChange = (event) => {
        const email = event.target.value;
        setEmailValid(Email.validateEmail(email));
        setEmail(email);
        props.booking.patronEmail = email;
    }

    const [patronNames, setPatronNames] = useState(props.booking.patronNames ? props.booking.patronNames : []);

    const onPatronNameChange = (index, event) => {
        const newPatronNames = [...patronNames];
        newPatronNames[index] = event.target.value;
        setPatronNames(newPatronNames);
        props.booking.patronNames = newPatronNames;
    };

    const addPatronName = () => {
        const newPatronNames = [...patronNames, ''];
        setPatronNames(newPatronNames);
        props.booking.patronNames = newPatronNames;
    };

    const deletePatronName = (index) => {
        const newPatronNames = patronNames.filter((_, i) => i !== index);
        setPatronNames(newPatronNames);
        props.booking.patronNames = newPatronNames;
    };

    const patronNamesValid = patronNames && patronNames.length > 0 && patronNames.every(name => name.length > 0);

    return (
        <Popover open={props.isOpen}
            anchorReference="anchorPosition"
            anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
            transformOrigin={{vertical: 'center', horizontal: 'center'}}>
                    
            <Box style={{margin: 20, width: maxWidth}}>
                <Typography variant="subtitle1" style={{fontWeight: 'bold', marginBottom: 20}}>Edit Patron Information</Typography>  

                {props.booking ?
                    <Fragment>
                        <TextField style={{marginBottom: 2}} label="Email Contact" size='small' variant="outlined" fullWidth value={email} onChange={onEmailChange} InputLabelProps={{ shrink: true}}/>
                        {emailValid ? 
                            <div style={{marginBottom: 20, height: 12}}></div>
                            : 
                            <div style={{color: 'red', fontSize: 12, fontStyle: 'italic', marginBottom: 20, marginRight: 20, height: 12, textAlign: 'right'}}>Invalid email address</div>
                        }

                        {!patronNames || patronNames.length === 0 ?   
                            <div style={{color: 'red', fontSize: 12, fontStyle: 'italic', marginBottom: 20, height: 12}}>At least one Patron required</div>
                            :
                            null
                        }
                        
                        {patronNames.map((name, index) => (
                                <Box key={index} display="flex" alignItems="center" mb={2}>
                                    <TextField
                                        label={"Patron " + (index + 1) + " Name"}
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        value={name}
                                        onChange={(event) => onPatronNameChange(index, event)}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <Tooltip title="Delete Patron" placement="top">
                                        <IconButton edge="end" onClick={() => deletePatronName(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                    
                                </Box>
                            ))
                        }

                        <div style={{display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                            <Button onClick={addPatronName} color="primary" variant="outlined">
                                Add Patron
                            </Button>
                        </div>

                    </Fragment>
                    : null
                }

                <Container style={{display: 'flex', justifyContent: 'center', marginTop: 20, gap: 20}}>    
                    <Button disabled={!emailValid  || !patronNamesValid } onClick={props.closeCallback} color="primary" variant="outlined">{props.hasCancelButton ? "Apply" : "Done"}</Button>
                    {props.hasCancelButton ?
                        <Button onClick={props.cancelCallback} variant="outlined">Cancel</Button>
                        : null
                    }
                </Container>
            </Box>

        </Popover>
    );
    
}