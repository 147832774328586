import React, { useState } from 'react';
import { Button, Box, Popover, Typography, Checkbox } from '@material-ui/core';

import { Service } from '../models/Service';
import { ThemeColors } from '../Theme';

/**
 * This function allows editing of assignable resources on a Service.  The Service is modified in place.
 * 
 *  The user must supply the following props:
 *      isOpen (boolean): True if the popover is open, false otherwise
 *      service: the Service to edit
 *      resources: an array of Resource objects that can be assigned to the Service, the list is filtered to only show Resources
 *      
 * 
 *    onUpdate (function): A callback function that is called when the user clicks "Apply Changes"
 *    onCancel (function): A callback function that is called when the user clicks "Cancel"
 */


function ResourceSelect(props) {

    const [checked, setChecked] = useState(props.service.assignableResourceIds.includes(props.resource.id));

    // update the services's assignableResourceIds when the checkbox is clicked
    const onCheck = (event) => {
        const isChecked = event.target.checked;
        setChecked(isChecked);
        if (isChecked && !props.service.assignableResourceIds.includes(props.resource.id))
            props.service.assignableResourceIds.push(props.resource.id);
        else if (!isChecked && props.service.assignableResourceIds.includes(props.resource.id))
            props.service.assignableResourceIds = props.service.assignableResourceIds.filter(id => id !== props.resource.id);
        props.didCheck();
    }        
    
    return (<div style={{display: 'flex', alignItems: 'center', marginBottom: -5}}>
                <Checkbox color='primary' disabled={props.isReadOnly} checked={checked} onChange={onCheck}/>
                <Typography variant="body2">{props.resource.name}</Typography>
                <Typography variant="caption" style={{marginLeft: 20, color: 'gray', fontStyle: 'italic'}}>{props.resource.description}</Typography>
            </div>); 
}



export function AssignResourcesPopover(props) {

    const [update, setUpdate] = useState(0);

    const maxWidth = window.innerWidth * 0.5
    const isReadOnly = props.service && !props.service.canEdit();

    if (!props.service || !(props.service instanceof Service))
        return null;

    let resources = props.resources.filter(r => r.type === "Resource");   // Only show resources, not other services

    const okDisabled = isReadOnly || resources.length === 0;

    const needsResourcesChecked = props.service.assignableResourceIds.length === 0 && props.service.serviceParams.requireAvailableResource;

    const doUpdate = () => {
        if (update > 0) {
            props.onUpdate();
            setUpdate(0);  // reset the update counter for next time
        }
        else 
            props.onCancel();   // if no changes were made, just close the popover
    }

    return(

        <Popover open={props.isOpen}
            anchorReference="anchorPosition"
            anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
            transformOrigin={{vertical: 'center', horizontal: 'center'}}>
    
            <Box style={{margin: 20, width: maxWidth}}>
                <Typography variant="subtitle1" style={{fontWeight: 'bold', marginBottom: 20}}>{"Assign Resources to " + props.service.name}</Typography>  

                <Typography variant="body2" style={{color: 'gray', fontStyle: 'italic', marginBottom: 20}}>When a Booking is scheduled on this Service, the selected Resources
                    can be assigned to the Booking. Note that if you remove a Resource from this list, it will also be removed from any existing Bookings that it is assigned to.
                </Typography>

                {resources.map((resource, index) => <ResourceSelect key={index} service={props.service} resource={resource} isReadOnly={isReadOnly} didCheck={() => setUpdate(update+1)}/>)}

                {resources.length === 0 ? <Typography variant="body2" style={{color: ThemeColors.darkGray, fontStyle: 'italic', marginTop: 20}}>No Resources, please add one first</Typography>
                    : null}

                {needsResourcesChecked ? 
                    <Typography variant="body2" style={{color: 'red', fontStyle: 'italic', marginTop: 20}}>This Service is configured to require at least one Resource to be available</Typography>
                    : null
                }

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 20, marginTop: 20, marginBottom: 20}}>
                    {okDisabled ? 
                        <Button onClick={props.onCancel} style={{color: 'black'}} variant="outlined">{isReadOnly ? "Close" : "Cancel"}</Button>
                        :
                        <Button onClick={doUpdate} style={{color: 'green'}} variant="outlined">Done</Button>
                    }
                </div>
                
            </Box>
            
        </Popover>
    );
    
}