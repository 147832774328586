import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
    
import { IconButton, Tooltip, Grid, Typography, Paper, Button, Collapse, TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GetAppIcon from '@material-ui/icons/GetApp';
import SettingsIcon from '@material-ui/icons/Settings';

import { Booking } from '../models/Booking';
import { ThemeColors } from '../Theme'
import { Global } from '../models/Global'
import { PagedSlotList } from '../models/PagedSlotList'

import { RefundPopover } from '../components/RefundPopover'
import { EditBookingPatronPopover } from '../components/EditBookingPatronPopover'
import { RestComponent, DateUtils, ManageBitwiseCheckboxes, DateRangeSelector, PopupMenu, TextEntryPopover } from 'react-frontend-utils'

import { DateConversions } from '../utils/DateConversions'
import { set } from 'store';



/**
 * 
 * Required props:
 * type - "Booking" or "Event"
 * viewSlotInCalendarCallback - function to call to view a slot in the calendar
 */

export class SlotsListPage extends RestComponent {
  
    styles = {
        paperLabel: {
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 5,
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1
        },
        paper: {
            padding: 0,
            marginBottom: 20
        },
        id: {
            marginLeft: 'auto',
            maxWidth: 300,
        },
        table: {
            borderCollapse: 'collapse',
            width: '100%',
            marginBottom: 10,
            marginTop: 10
        },
        tableHeader: {
            borderBottom: '2px solid ' + ThemeColors.appBarBackground,
            textAlign: 'left',
            paddingBottom: 5,
            paddingRight: 10,
            marginBottom: 8,
            fontSize: 13,
            color: ThemeColors.darkGray,
            fontWeight: 'normal',
            textTransform: 'uppercase'
        },
        tableDataWithBorder: {
            textAlign: 'left',
            fontSize: 13,
            paddingRight: 10,
            borderBottom: '1px solid lightGray'
        },
        tableData: {
            textAlign: 'left',
            fontSize: 14,
            marginRight: 10,
            verticalAlign: 'center'
        },
        status: {
            margin: 'auto',
            alignContent: 'center',
            width: 230,
            textTransform: 'uppercase',
            padding: 2, 
            borderRadius: 2, 
            color: 'white', 
            textAlign: 'center'
        },
        details: {
            marginLeft: 30,
            marginBottom: 5,
            padding: 5,
            textAlign: 'left',
            borderRadius: 3,
            backgroundColor: ThemeColors.veryLightGray,
        },
        journal: {
            marginLeft: 30,
            marginBottom: 5,
        }
       
    };
    
    
    _timezone = Global.getTimezone();
    _currentDatabase = Global.getLastDatabase();

    _startOfThisMonth = DateConversions.now(this._timezone).startOf('month');
    _endOfThisMonth = this._startOfThisMonth.plus({month: 1}).minus({days: 1});

    _startOfLastMonth = this._startOfThisMonth.minus({month: 1});
    _startOfYear = DateConversions.now(this._timezone).startOf('year');
    _endOfYear = this._startOfYear.plus({year: 1}).minus({days: 1});
    _endOfNextYear = DateConversions.now(this._timezone).plus({year: 1}).endOf('year');

     //options for the search time range pulldown
    _searchTimeRangeOptions = [
        {label: "This month", startTime: this._startOfThisMonth.toUTC().valueOf(), endTime: this._endOfThisMonth.toUTC().valueOf()},
        {label: "Last month", startTime: this._startOfThisMonth.minus({month: 1}).toUTC().valueOf(), endTime: this._startOfThisMonth.minus({days: 1}).toUTC().valueOf()},
        {label: "Last 90 days", startTime: DateConversions.now(this._timezone).minus({days: 90}).toUTC().valueOf()},
        {label: "This year", startTime: this._startOfYear.toUTC().valueOf(), endTime: this._endOfYear.toUTC().valueOf()},
        {label: "Upcoming", startTime: DateConversions.now(this._timezone).startOf('day').toUTC().valueOf(), endTime: this._endOfNextYear.toUTC().valueOf()},
    ];
    

    constructor(props) {
        super(props);
    
        this.state.slotsPage = null;         //last fetched page - a PagedSlotList
               
        this.state.searchFromDate = DateConversions.luxonDateTimeToJsonDateString(this._startOfYear).split("T")[0];     // keep date only
        this.state.searchToDate = DateConversions.luxonDateTimeToJsonDateString(this._endOfYear).split("T")[0];         // keep date only
        
        this.state.stateCheckboxes = 0;           //Bitwise selected types to search for, from checkboxes only! (bit 0 = pending, bit 1 = active, bit 2 = cancelled)
        this.state.revealSet = new Set();         //Set of slots to reveal detail
        this.state.patronDataToSearch = "";       //Data in a patron name or email to search for
        this.state.textDataToSearch = "";       //Data in a slot title or description to search for

        this.state.refundPopupOpen = false;
        this.state.maxRefund = 0;
        this.state.slotCurrency = null;
        this.state.refundSlot = null
        this.state.commentPopupOpen = false;
        this.state.commentSlot = null;
        this.state.managePatronSlot = null;

        this.state.editBookingPatronPopoverOpen = false;
    }
    
    
    
    /**
     * When the page loads, immediately fetch the first page of Slots
     */
    componentDidMount() {
        super.componentDidMount();
        window.addEventListener("databaseChangeEvent", this._databaseChanged);
        
        //fetch all slots, first page
        this._fetchSlots();  
    }
    
  
    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("databaseChangeEvent", this._databaseChanged);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.type !== nextProps.type) {
            setTimeout(() => this._fetchSlots(0), 0);  //refetch when type changes
        }
    }


    // When the database changes, refetch the resources (which will refresh the calendar) and fetch the timezone
    _databaseChanged = () => {
        if (Global.getLastDatabase() !== this._currentDatabase) {
            console.log("Database changed to " + Global.getLastDatabase() + ", refetching resources");
            this._timezone = Global.getTimezone();        // this will have updated if the database changed
            this._currentDatabase = Global.getLastDatabase();
            this.setState({slotsPage: []});         // clear list of slots
        }
    }
    
    
    //Fetch a specific page, using search fields if set.
    //If forDownload is set, download as csv, otherwise normal fetch to display
    _fetchSlots = (page = 0, forDownload = false) => {
        
        this.setState({slotsPage: null});  //clear the list of slots

        let queryString = "?page=" + page;
                        
        if (this.state.stateCheckboxes)  //state checkboxes are set
            queryString += "&states=" + this.state.stateCheckboxes;
        if (this.state.searchFromDate)
            queryString += "&fromDate=" + this.state.searchFromDate;
        if (this.state.searchToDate) {
            const endOfDayDate = this.state.searchToDate + "T23:59:59";     //search goes through the end of the day
            queryString += "&toDate=" + endOfDayDate;
        }
       
        if (this.state.textSearch)
            queryString += "&text=" + encodeURIComponent(this.state.textSearch);
        if (this.state.patronDataToSearch)
            queryString += "&patronData=" + encodeURIComponent(this.state.patronDataToSearch);
        
        if (forDownload) {
            const filename = this.props.type + " Export on " + DateUtils.downloadTimeString() + ".csv";
            this.secureFileDownload("/bk/" + this._currentDatabase + "/slots/" + this.props.type + "/download" + queryString, filename, null, this._fetchErrorCallback); 
        }
        else {
            this.incrementBusy();
            this.secureJSONFetch("/bk/" + this._currentDatabase + "/slots/" + this.props.type, {}, this._fetchSlotsCallback, this._fetchErrorCallback, queryString); 
        }
                
    }
    
    //Callback for fetching Slots - response is json of PagedSlotList
    _fetchSlotsCallback = (response) => {
        if (response) {            
            const sl = new PagedSlotList(response, this.props.type);
            this.setState({slotsPage: sl});
        }            
        this.decrementBusy();
    }
    

    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }

    _slotModifiedCallback = (response) => {
        this.decrementBusy();
        this._refresh();
    }

  
    //If the slot is in the reveal list, remove it, if it is not, add it (toggle the transaction view)
    _revealSlotDetail = (s) => {
        const toReveal = this.state.revealSet;
        
        if (toReveal.has(s))
            toReveal.delete(s);
        else
            toReveal.add(s);
        
        this.setState({revealSet: toReveal});
    }
    

    //fetch the previous page
    _prevPage = () => {  
        
        if (!this.state.slotsPage || this.state.slotsPage.isFirst())
            return;
        
        this._fetchSlots(this.state.slotsPage.pageNo-1); 
        window.scrollTo(0, 0);  //jump to the top

    }
    
    //fetch the next page
    _nextPage = () => {  
        
        if (!this.state.slotsPage || this.state.slotsPage.isLast())
            return;
        
        this._fetchSlots(this.state.slotsPage.pageNo+1); 
        window.scrollTo(0, 0);  //jump to the top
    }
    
    
    _dateChangeCallback = (start, end) => {
        this.setState({searchFromDate: start, searchToDate: end});
    }
    
    _dateParseError = (label, error) => {
        this.showConfirmAlert("Error in Date Field \"" + label + "\"", error, 'red');
    }
    

    _checkboxStateChanged = (json, newValue) => {
        this.setState({stateCheckboxes: newValue});
    }
    
    _download = () => {
        this._fetchSlots(0, true);  //fetch for download        
    }

    _refresh = () => {
        this._fetchSlots(this.state.slotsPage ? this.state.slotsPage.pageNo : 0);   //just fetch the same page again
    }
    
    _search = () => {
        this._fetchSlots(0, false);
    }


    _addComment = (slot) => {
        this.setState({commentPopupOpen: true, commentSlot: slot});
    }
        
    _commentOkCallback = (text) => {
        this.setState({commentPopupOpen: false});
        this.incrementBusy();
        this.secureJSONFetch("/bk/slots/" + this.state.commentSlot.id + "/comment", {method: 'POST', body: text}, this._slotModifiedCallback, this._fetchErrorCallback);
    }

    
    _managePatrons = (slot) => {
        this.setState({managePatronSlot: slot, editBookingPatronPopoverOpen: true});
    }

    _finishEditBookingPatrons = () => {
        this.setState({editBookingPatronPopoverOpen: false});
        this.incrementBusy();
        this.secureJSONFetch("/bk/slots", {method: 'POST', body: this.state.managePatronSlot.toJsonForPost()}, this._slotModifiedCallback, this._fetchErrorCallback);
    }

    // user selects "Delete", show a confirm alert
    _deleteSlot = (slot) => {

        let recurrMsg = ""; 
        if (slot.recurrenceId)
            recurrMsg = " This is a recurring " + slot.type + ". Only this occurrence will be deleted. To modify the series, go to the Calendar.";

        const cancelInsteadOfDelete = slot.state === "ACTIVE";

        if (cancelInsteadOfDelete) {

            const msg = slot.type === "Booking" ? "This Booking is confirmed with the patron." : "This Event has attendees."

            this.showConfirmAlert("Cancel Active " + slot.type, 
                                    msg + " Are you sure you want to cancel \"" + slot.title + "\"?" + recurrMsg, 'black',
                                    "No", 
                                    () => this._confirmDeleteSlot(slot),
                                    "Cancel",
                                    'red');
        }
        else {
            this.showConfirmAlert("Delete " + slot.type, 
                                "Are you sure you want to delete \"" + slot.title + "\"?" + recurrMsg, 'black',
                                "No", 
                                () => this._confirmDeleteSlot(slot),
                                "Delete",
                                'red');
        }
    }

    // callback from the confirm alert when the user confirms the delete, try and delete it from the server
    _confirmDeleteSlot = (slot) => {
        this.incrementBusy();
        this.secureJSONFetch("/bk/slots/" + slot.id, {method: 'DELETE'}, this._slotModifiedCallback, this._fetchErrorCallback);
    }

    _refund = (slot) => {   
        this.setState({refundPopupOpen: true, maxRefund: slot.calculatedCost - slot.refundedAmount, slotCurrency: slot.isoCurrency, refundSlot: slot});
    }

    _startRefund = (amount, comment) => {
        this.setState({refundPopupOpen: false});
        this.incrementBusy();

        const slot = this.state.refundSlot;
        const body = {amount: amount, comment: comment};
        this.secureJSONFetch("/bk/slots/" + slot.id + "/refund", {method: 'POST', body: JSON.stringify(body)}, 
                             this._slotModifiedCallback, this._fetchErrorCallback);
    }



    _colorForState = (state) => {
        switch (state) {
            case "CREATED":
                return ThemeColors.mediumGray;
            case "ACTIVE":
                return 'green';
            case "PENDING_CANCEL":
            case "CANCELLED":
                return 'red';
            default:
                return 'black';
        }
    }
            

    //Action items are items for the right popup menu
    _getActionItems = (slot) => {
        
        const actionItems = [];

        actionItems.push({label: "View in Calendar", selectCallback: () => {this.props.viewSlotInCalendarCallback(slot.id)}, icon: null});

        actionItems.push({label: "Comment", selectCallback: () => {this._addComment(slot);}, icon: null});

        if (slot.state === "CREATED")
            actionItems.push({label: "Delete", selectCallback: () => {this._deleteSlot(slot);}, icon: null});

        if (slot.state === "ACTIVE")
            actionItems.push({label: "Cancel", selectCallback: () => {this._deleteSlot(slot);}, icon: null});

        if (slot instanceof Booking && slot.refundable())
            actionItems.push({label: "Refund", selectCallback: () => {this._refund(slot);}, icon: null});

        if (slot instanceof Booking && (slot.state === "ACTIVE" || slot.state === "CREATED"))
            actionItems.push({label: "Edit Patrons", selectCallback: () => {this._managePatrons(slot);}, icon: null});

        return actionItems;
    }

    //Render a row of the table with the specified Slot. In the Slot is null, render the header row
    _renderRow = (slot, key) => {
        
        const columns = 7;

        //Render the header
        if (!slot) {
            return (
                <tr key={key} style={this.styles.tableStyle}>
                    <th style={{...this.styles.tableHeader, paddingRight: 0, width: '30px'}}/>
                    <th style={{...this.styles.tableHeader, width: 250}}>Start Date</th>
                    <th style={{...this.styles.tableHeader, width: 130}}>Duration (min)</th>
                    <th style={{...this.styles.tableHeader, width: 130, textAlign: 'center'}}>Payments</th>
                    <th style={{...this.styles.tableHeader, textAlign: 'center'}}>Status</th>
                    <th style={{...this.styles.tableHeader, width: 250}}>Name</th>
                    <th style={{...this.styles.tableHeader, paddingRight: 0, width: '30px'}}/>
                </tr>
            );
        }
            
        const isRevealed = this.state.revealSet.has(slot);
        const leftIcon = isRevealed ? <ArrowDropDownIcon/> : <ArrowRightIcon/>;
        const startDate = DateConversions.dateTimeStr(slot.start);
        const duration = slot.duration() / 60;
    
        let bookingPaid = null;
        let payBadge = null;
        if (slot instanceof Booking) {
            if (slot.paymentIntent && slot.paidOn) { 
                if (slot.refundedAmount > 0)
                    bookingPaid = {background: 'cyan', textColor: 'black', text: slot.refundedAmount === slot.calculatedCost ? 'REFUNDED' : 'PARTIALLY REFUNDED'};
                else
                    bookingPaid = {background: 'green', textColor: 'white', text: 'PAID'};
            
                payBadge = <div style={{backgroundColor: bookingPaid.background, color: bookingPaid.textColor, padding: 2, borderRadius: 2, textAlign: 'center'}}>
                                    {bookingPaid.text}

                            </div>
            }
        }
                                
        return (
            <Fragment key={key}>
                <tr style={this.styles.tableStyle}>

                    <td style={this.styles.tableData}>
                        <IconButton edge="start" onClick={() => this._revealSlotDetail(slot)}>
                            {leftIcon}
                        </IconButton>
                    </td>

                    <td style={this.styles.tableData}>{startDate}</td>
                    <td style={this.styles.tableData}>{duration}</td>
                    <td style={this.styles.tableData}>{payBadge}</td>

                    <td style={this.styles.tableData}>
                        <div style={{...this.styles.status, backgroundColor: this._colorForState(slot.state)}}>
                            {slot.status()}
                        </div>
                    </td>
                   
                    <td style={this.styles.tableData}>{slot.title}</td>

                    <td style={{...this.styles.tableData, paddingRight: 0}}>
                        <PopupMenu menuIcon={(<SettingsIcon style={{fontSize: '20', color: ThemeColors.settingsOlive}}/>)}  
                                    menuItems={this._getActionItems(slot)} 
                                    menuTooltipText={"Actions"}/>
                    </td>
                </tr>
                <tr style={this.styles.tableStyle}>
                    <td colSpan={columns} align='center' style={this.styles.tableDataWithBorder}>
                        <Collapse in={isRevealed}>
                            <Typography variant='body2' style={{marginLeft: 35, fontWeight: 'bold'}}>Details</Typography>
                            <div style={this.styles.details} dangerouslySetInnerHTML={{ __html: slot.details() }} />
                            <div style={this.styles.journal}>
                                {slot.renderJournal()}
                            </div>
                        </Collapse>
                    </td>
                </tr>
            </Fragment>
        );
    }
    

    
    //------------------------------- RENDER ----------------------------------------------
    
    render() {
       
                                
        const page = this.state.slotsPage;

        let checkboxLabels = [];
        if (this.props.type === "Booking") {
            checkboxLabels.push({name: "Reserved", tooltip: "Patron has reserved the Booking"});
            checkboxLabels.push({name: "Booked", tooltip: "The Booking is confirmed (payment may be pending)"});
            checkboxLabels.push({name: "Pending Cancel", tooltip: "A cancel request is pending for the Booking"});
            checkboxLabels.push({name: "Cancelled", tooltip: "The Booking has been cancelled"});
        }
       else {
            checkboxLabels.push({name: "Created", tooltip: "The Event has been created"});
            checkboxLabels.push({name: "Active", tooltip: "At least one Patron has signed up for the Event"});
            checkboxLabels.push({name: "Pending Cancel", tooltip: "A cancel request is pending for the Event"});
            checkboxLabels.push({name: "Cancelled", tooltip: "The Event has been cancelled"});
        }
       
        const showing = (page && page.slots.length > 0) ? "Displaying " + (page.index + 1) + "-" + (page.index + page.slots.length) : null;            
                
        return (                        
             <Fragment>
                {this.getConfirmAlertComponent()}   

                {this.state.managePatronSlot ?
                    <EditBookingPatronPopover isOpen={this.state.editBookingPatronPopoverOpen} booking={this.state.managePatronSlot}
                                            closeCallback={this._finishEditBookingPatrons} hasCancelButton={true} cancelCallback={() => this.setState({editBookingPatronPopoverOpen: false})}/>
                    : null
                }

                <TextEntryPopover isOpen={this.state.commentPopupOpen} showSkip={false} multiline={true} title={"Add Comment for " + this.props.type} 
                                  okCallback={this._commentOkCallback} cancelCallback={() => this.setState({commentPopupOpen: false})}/>

                <RefundPopover isOpen={this.state.refundPopupOpen} maxVal={this.state.maxRefund} currency={this.state.slotCurrency}
                               okCallback={this._startRefund} cancelCallback={() => this.setState({refundPopupOpen: false})}/>                 
           

                <div style={{display: 'flex', gap: 20, marginLeft: 10, marginRight: 10, justifyContent: 'right', alignItems: 'center'}}>
            
                    {this.state.isBusy ? this.getBusyComponent('left', {marginLeft: 20, padding: 15}, 30) : 
                        <Tooltip title="Refresh">
                            <div style={{display: 'flex'}}>
                                <IconButton edge="end" onClick={this._refresh} color='primary' style={{marginLeft: 0, marginRight: 1, marginBottom: 1}}>
                                    <RefreshIcon fontSize="large"/>
                                </IconButton>
                            </div>
                        </Tooltip>}
           
                </div>
                
                <Paper style={this.styles.paper}>
                    
                    <Typography variant="body2" style={this.styles.paperLabel}>{"Search " + this.props.type + "s"}</Typography>  

                    <Grid container direction="row" spacing={3} style={{padding: 20}}>

                        <Grid item md={4} sm={6} xs={12}>

                            <DateRangeSelector calendarColor={ThemeColors.calendarColor}
                                                dateFormat={null}
                                                timeOptions={this._searchTimeRangeOptions}
                                                allowFuture={true}
                                                minYear={2024}
                                                maxYear={DateUtils.currentYear() + 50}
                                                ref={this._dateRangeRef}
                                                initialTimeRange="This Year"
                                                initialStartDate={this.state.searchFromDate}
                                                initialEndDate={this.state.searchToDate}
                                                onDateChange={this._dateChangeCallback}
                                                onParseError={this._dateParseError}/>

                        </Grid>

                        <Grid item md={4} sm={12} xs={12}>
                            <ManageBitwiseCheckboxes style={{marginTop: -17}} json="stateCheckboxes" label="States" labels={checkboxLabels} onChange={this._checkboxStateChanged} initialValue={this.state.stateCheckboxes} />
                        </Grid>

                        <Grid item md={4} sm={12} xs={12}>
                            <Tooltip title={"Search text fields: " + this.props.type + " title/description (may be slow)"}>
                                <TextField label="Title/Description Search" inputProps={{style: {fontSize: 14}}} style={{marginBottom: 20}}
                                           onChange={(event) => this.setState({textDataToSearch: event.target.value.trim()})} variant="outlined" fullWidth={true} InputLabelProps={{ shrink: true}} />
                            </Tooltip>
                            <Tooltip title="Search patron names">
                                <TextField label="Patron Name Search" inputProps={{style: {fontSize: 14}}}
                                           onChange={(event) => this.setState({patronDataToSearch: event.target.value.trim()})} variant="outlined" fullWidth={true} InputLabelProps={{ shrink: true}} />
                            </Tooltip>
                        </Grid>
                    </Grid>

                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                        <Tooltip title={"Search and Display " + this.props.type + "s below"}>
                            <Button fullWidth onClick={this._search} variant="outlined" color='primary' style={{margin: 20, maxWidth: 200}} component="label" startIcon={<SearchIcon />}>
                                Search
                            </Button>
                        </Tooltip>

                        <Tooltip title={"Search and Download " + this.props.type + "s"}>
                            <Button fullWidth onClick={this._download} variant="outlined" color='primary' style={{margin: 20, maxWidth: 200}} component="label" startIcon={<GetAppIcon/>}>
                                Download
                            </Button>
                        </Tooltip>

                    </div>

                </Paper>
                
                <div style={{marginTop: 15}}/>
                
                {page ?
                     <div>   
                     
                        <table style={this.styles.table}>
                            <thead>
                               {this._renderRow(null, 0) /*render header*/ }
                            </thead>
                            <tbody>
                                {page.slots.map((slot, index) => this._renderRow(slot, index+1))}
                            </tbody>
                        </table>
                        <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                            <Typography variant="body2" style={{color: ThemeColors.darkGray}}>{showing}</Typography> 
                            <div style={{marginLeft: 'auto'}}>
                                {!page.isFirst() ? <Button onClick={this._prevPage} variant="outlined" color='primary' component="label" >
                                               Prev
                                           </Button>
                                           : null}

                                {!page.isLast() ? <Button onClick={this._nextPage} variant="outlined" color='primary' style={{marginLeft: 10}} component="label" >
                                               Next
                                           </Button>
                                           : null}
                            </div>            
                        </div>                        

                    </div>
                    
                    
                : null}
                
                
            </Fragment>
        );
        
    }
}



export default withCookies(withRouter(SlotsListPage));

