import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import { RestComponent } from 'react-frontend-utils' 

import { Global } from '../models/Global'
import { ThemeColors } from '../Theme'
import { Booking } from '../models/Booking'
import { PatronServiceCalendarSlot } from '../components/PatronServiceCalendarSlot'
import { PatronServiceCalendarButton } from '../components/PatronServiceCalendarButton'
import { Typography, Button } from '@material-ui/core';
import { DateConversions } from '../utils/DateConversions';


/**
 * Page 1 of the Booking process. Allows the user to select a date and time for the booking. Uses one of two calendar components, depending on the service's
 * minimum slot time. If the service uses a slot calendar, the user can select a time slot. If the service uses a button calendar, the user can select a time
 * by clicking on a button.
 * 
 * The following props are required:
 *  - service: the Service object that the user is booking
 *  - prevPageCallback: a callback that returns to the previous page
 *  - nextPageCallback: a callback that is called when the user has selected a booking slot
 */
export class BookServicePage1 extends RestComponent {


    _prevPageCallback;
    _nextPageCallback;
    _calendarRef = React.createRef();
    _timezone = Global.getTimezone();

    constructor(props) {
        super(props);
    
        this._prevPageCallback = props.prevPageCallback;  //callback that returns to PatronBooking
        this._nextPageCallback = props.nextPageCallback;  //callback when Booking slot is created
        this.state.selectedBooking = null;
    }
    
    
    componentDidMount() {
        super.componentDidMount(); 
        window.addEventListener("popstate", this._prevPageCallback);
    }
   
    componentWillUnmount() {
        super.componentWillUnmount();       
        window.removeEventListener("popstate", this._prevPageCallback);
    }

    //--------------------------------------------------------------------------------------------------------------------------
    //-------------------------------------------- CALENDAR CALLBACK -----------------------------------------------------------
    //--------------------------------------------------------------------------------------------------------------------------

    // Fetches a list of Blocks and Availabilities for the given date range for the service. Blocks are unavailable times either because there
    // is a Booking (and the service does not allow overlapping bookings) there are no available resources. Availabilities are the expanded 
    // available times for the service, outside the business hours and the season start/end dates.
    _fetchSlots = (jsonDateStart, jsonDateEnd, serviceID, successCallback, failureCallback) => {

        // If we are using the button calendar, we tell the server to include all the individual slots on each Resource, not just when they overlap. Because
        // the button calendar uses 15 minute increments, we need to check against the entire button duration
        const allResources = this.props.service.usesSlotCalendar() ? "" : "&allResources=true";

        this.secureJSONFetch("/patron/services/" + serviceID + "/slots?fromDate=" + jsonDateStart + "&toDate=" + jsonDateEnd + allResources, {}, 
                             (response) => successCallback(response), (error) => failureCallback(error)); 

    }


    _setBooking = (booking) => {
        console.log("Booking set: ", booking);
        
        if (this._calendarRef.current.setBooking)
            this._calendarRef.current.setBooking(booking);
        this.setState({selectedBooking: booking});
    }

    _dateSelected = (startTime, endTime) => {

        if (!startTime || !endTime) {
            this._setBooking(null);
            return;
        }

        const newBooking = Booking.createNew(startTime, endTime, this.props.service);
        this._setBooking(newBooking);
    }


    // Extend slot time by 1 increment, if it won't exceed the max booking time
    _extendSlotTime = () => {

        const booking = this.state.selectedBooking; 
        if (!booking)
            return;

        const maxBookingTimeMin = this.props.service.serviceParams.incrementsMinutes * this.props.service.serviceParams.maxIncrements;
        if (booking.duration() / 60 < maxBookingTimeMin) {
            const newEndTime = booking.end.plus({minutes: this.props.service.serviceParams.incrementsMinutes});
            booking.end = newEndTime;
            this._setBooking(booking);
        }
        else 
            this.showConfirmAlert("Maximum Booking Time Reached", "Your booking can only be " + maxBookingTimeMin + " minutes long", 'red');
    }

    
    render() {
       
        const isMobile = window.innerWidth < 600;

        const useSlotCalendar = this.props.service.usesSlotCalendar();

        if (this.state.isBusy)
            return this.getBusyComponent('center', {marginTop: 20});

        let durationString = "";
        let startStr = "";
        if (this.state.selectedBooking) {
            durationString = this.state.selectedBooking.patronDurationString(this.props.service);
            startStr = "Selected: " + DateConversions.dateTimeStr(this.state.selectedBooking.start);
        }

        let msg;
        if (useSlotCalendar) {
            msg = "To select date and time, choose a day and then select a time slot.";        
            if (this.props.service.serviceParams.maxIncrements > 1)
                msg += " You can select up to " + this.props.service.serviceParams.maxIncrements + " consecutive slots. Click on the first slot and then drag down to select multiple slots.";
            else
                msg += " Click on the time slot to select it.";

            msg += " Greyed out slots are not available.";
        }
        else {
            if (this.props.service.serviceParams.maxIncrements === 1)
                msg = "Click on any available green button to select your time slot.";
            else
                msg = " You can select up to " + this.props.service.serviceParams.maxIncrements + " consecutive time slots. Click on the consecutive buttons to select those times.";    
        }

        return (
            <Fragment>
                {this.getConfirmAlertComponent()}

    
                <Button disabled={!this.state.selectedBooking} variant='contained' onClick={() => this._nextPageCallback(this.state.selectedBooking)} 
                        style={{position: 'fixed', right: 20, zIndex: 10000, backgroundColor: this.state.selectedBooking ? 'green' : ThemeColors.veryLightGray, color: 'white'}}>Proceed to Book</Button>
                
                <Typography variant='h6' style={{marginRight: 150}}>{this.props.service.name}</Typography>
                <Typography variant={isMobile ? 'body2' : 'body1'} style={{color: 'gray', fontStyle: 'italic', marginBottom: 10, marginRight: 150}}>{this.props.service.description}</Typography>
                <hr/>

                <Typography variant={isMobile ? 'body2' : 'body1'}>{msg}</Typography>
                <hr/>
                <div style={{display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: isMobile ? 0 : 20, alignItems: 'center', justifyContent: 'center', color: 'green', height: isMobile ? 60 : 40}}>
                    <Typography variant='body1' style={{fontWeight: 'bold'}}>{startStr}</Typography>
                    <Typography variant='body1' style={{fontWeight: 'bold'}}>{durationString}</Typography>
                </div>

                <div style={{marginLeft: 'auto', marginRight: 'auto', marginTop: 20, maxWidth: 1024}}>
                    {useSlotCalendar ?
                        <PatronServiceCalendarSlot ref={this._calendarRef} 
                                                    initialTimezone={this._timezone} 
                                                    fetchSlots={this._fetchSlots}
                                                    dateSelectCallback={this._dateSelected}
                                                    extendSlotTime={this._extendSlotTime}
                                                    loadingCallback={this._calendarBusy}
                                                    service={this.props.service}
                                                    />
                :
                        <PatronServiceCalendarButton ref={this._calendarRef} 
                                                    initialTimezone={this._timezone} 
                                                    fetchSlots={this._fetchSlots}
                                                    dateSelectCallback={this._dateSelected}
                                                    loadingCallback={this._calendarBusy}
                                                    service={this.props.service}
                                                    />
                    }
                </div>
            </Fragment>
        );
    }

}



export default withCookies(withRouter(BookServicePage1));

