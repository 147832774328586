import React from 'react';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import adaptivePlugin from '@fullcalendar/adaptive'
import interactionPlugin from '@fullcalendar/interaction';
import luxonPlugin from '@fullcalendar/luxon3'
import { toLuxonDateTime } from '@fullcalendar/luxon3';

import { DateConversions } from '../utils/DateConversions'

import { Availability } from '../models/Availability'
import { Block } from '../models/Block'

import { ThemeColors } from '../Theme';

/**
 * Wrapper around the FullCalendar component, to display a calendar for a Service that a Patron will use to select a Booking time. This calendar uses calendar events
 * to show available times like buttons to select from. It can be used when the slot intervals are 15 minutes or more. The available times are computed on each day
 * from the first available time, by increment, to the last available time. If any Booking, fully used available resource, or block interferes with those intervals,
 * that interval is removed as an available time. The server automatically expands slots to 15 minute intervals.
 * 
 * User must supply the following props:
 * 
 *     service (Service object): The Service to fetch slots for from the server
 *     initialTimezone (String): The initial timezone to use for the calendar
 *     fetchSlots(start, end, serviceID, successCallback, failureCallback): A function that fetches the slots from the server for the given time range for the Service.
 *                start: json string of the start date/time of the range
 *                end: json string of the end date/time of the range
 *                serviceID: the Service to fetch slots for
 *                successCallback: A callback function that is called with the array of slots fetched from the server
 *                failureCallback: A callback function that is called with an error message if the fetch fails
 *  
 * Optional props:
 * 
 *     dateSelectCallback (function): A callback function that is called when the user clicks on a date/time. 
 *                                    The function is called with the start and end date/time of the selected range
 *                                    
 *     loadingCallback (function): A callback function that is called when the calendar is loading events and rendering. The function is called with a boolean (true if loading)
 *     requiredSlots (number): The number of slots the user must select (for rescheduling an existing booking)
 * 
 */

const MULTI_EVENT_CLICK_TIME = 5000;  // time in milliseconds to allow multiple events to be clicked in succession

export class PatronServiceCalendarButton extends React.Component {

    _bookingSlotID = "PATRON_BOOKING_SLOT";

    _ref = React.createRef();
    _timezone;
    _service;
    _dateSelectCallback;

    _highlightedEvents = [];
    
    // Cache the last fetch request, when resizing the calendar or when it re-renders itself it will refetch the events, but if the range is the same
    // as the last fetch, we don't need to refetch
    _eventCache = {start: null, end: null, events: []}


    _hasMouse = matchMedia('(pointer:fine)').matches;
    
    _getApi = () => {
        if (this._ref.current)
            return this._ref.current.getApi();
        return null;
    }


    constructor(props) {
        super(props);
        this._timezone = props.initialTimezone;
        this._service = props.service;
        this._dateSelectCallback = props.dateSelectCallback;
    }

    componentDidMount() {
        // Before the calendar is mounted, nothing is fetched on initial render (because api is required), so we
        // refetch events after the component is mounted
        this._getApi().refetchEvents();
    }

    // See if the component should re-render, re-rendering is expensive because it refetches the events. So we only do it if we have to
    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }


    // -----------------------------  EVENT FETCHING  -----------------------------

     // Create a Slot subclass object from a JSON string, we only accept Blocks and Availabilities here
     _slotFromJson(json) {
        switch (json.type) {
            case "Availability":
                return new Availability(json, this._timezone);
            case "Block":
                return new Block(json, this._timezone);
            default:
                console.error("Unhandled slot type: " + json.type);
                return null;
        }
    }

    // Since we are using the Luxon plugin, we must use the toLuxonDateTime function to convert the JSDate managed by the calendar 
    // to a Luxon DateTime object, so we can use the our DateConversions functions to convert to a JSON string
    _fullCalendarDateToUTCJsonDateString = (date) => {
        
        const luxonDateTime = toLuxonDateTime(date, this._getApi());
        return DateConversions.luxonDateTimeToJsonDateString(luxonDateTime)
    }

    // Since we are using the Luxon plugin, we must use the toLuxonDateTime function to convert the JSDate managed by the calendar
    // to a Luxon DateTime object
    _fullCalendarDateToLuxonDateTime = (date) => {
        return toLuxonDateTime(date, this._getApi());
    }

    // Given a Slot object, convert it to a FullCalendar event
    _incrementToEvent = (increment) => {
        const isAllDay = this._service.serviceParams.incrementsMinutes === 24 * 60
        return {
            id: increment.start.valueOf(),         // needs a unique ID
            start: increment.start.toISO(),          // needs ISO string
            end: increment.end.toISO(),              // needs ISO string
            display: isAllDay ? "background" : "block",
            allDay: isAllDay,
            color: ThemeColors.calendarDateButton,
            textColor: 'black',
        };
    }

    _getBusinessHours = (dateTime, availabilities) => {
        const businessHours = this._service.businessHoursForDate(dateTime);
        if (!businessHours)
            return null;

        //If any availability ends at the business hour start, expand the business hour to encompass it. If any availability starts at the business hour end, 
        //expand the business hour to encompass it
        for (let availability of availabilities) {

            const availOffset = DateConversions.dayMinuteOffset(availability.start, availability.end);

            if (availOffset.endMin >= businessHours.openMin && availOffset.startMin < businessHours.openMin && availability.start.hasSame(dateTime, 'day')) {
                console.log("Expanding business hours open from " + businessHours.openMin + " to " + availOffset.startMin);
                businessHours.openMin = availOffset.startMin;
            }

            if (availOffset.startMin <= businessHours.closeMin && availOffset.endMin > businessHours.closeMin && availability.end.hasSame(dateTime, 'day')) {
                console.log("Expanding business hours close from " + businessHours.closeMin + " to " + availOffset.endMin);
                businessHours.closeMin = availOffset.endMin;
            }
        }

        return businessHours;
    }


    _timeRangeBookable = (start, end, blocks, availabilities) => {

        // Cannot book outside the season range
        if (this._service.serviceParams.seasonBookingStart && start < this._service.serviceParams.seasonBookingStart)
            return false;
        if (this._service.serviceParams.seasonBookingEnd && end > this._service.serviceParams.seasonBookingEnd)
            return false;
        
        // Cannot book in the past or from now until the bookingLeadTimeMinutes
        if (start <= DateConversions.now(this._timezone).plus({minutes: this._service.serviceParams.bookingLeadTimeMinutes}))
            return false;

        // Cannot book if the start does not start on the scheduling interval
        if (start.minute % this._service.serviceParams.schedulingInterval !== 0)
            return false;

        let resourcesInUse = [];  // resources that are in use for this time range
        // Check to see if the time range is within a block, and if the block would interfere with the booking
        for (let block of blocks) {
            if ((start >= block.start && start < block.end) || (end > block.start && end <= block.end)) {   // intersects the block
                
                // See if this was actually a Booking (obfuscated as a Block), and the server only includes those when overlap is not allowed, so we must disallow
                // It's a Booking on this service if it has a Resource ID that is the Service ID
                for (let resourceId of block.resourceIds) {
                    if (resourceId === this._service.id)
                        return false;   // it's really a booking on our Service
                }

                if (this._service.serviceParams.requireAvailableResource)
                    resourcesInUse.push(...block.resourceIds);  // add all the resources the block uses to the list
            }
        }

        if (this._service.serviceParams.requireAvailableResource) {
            // Remove any resource that is not part of the Service
            resourcesInUse = resourcesInUse.filter(id => this._service.hasAssignableResourceId(id));

            // If all resources are in use (if there are any), the time is not bookable
            if (resourcesInUse.length === this._service.assignableResourceIds.length)
                return false;
        }

        // If the time is fully contained with an availability, it is bookable
        for (let availability of availabilities) {
            if (start >= availability.start && end <= availability.end)
                return true;
        }

        // Get the business hours, expanding them for any availabilities that are adjacent to the business hours
        const businessHoursThisDay = this._getBusinessHours(start, availabilities);
        if (!businessHoursThisDay)      //not open for business this day
            return false;

        
        // Check if the start and end are within the business hours open and close
        const dayMinuteOffset = DateConversions.dayMinuteOffset(start, end);
        if (dayMinuteOffset.startMin < businessHoursThisDay.openMin || dayMinuteOffset.endMin > businessHoursThisDay.closeMin)
            return false;

        return true;  //its available!
    }


    _setEarliestAndLatestAvailableBookings = (availableIncrements) => {

        let earliestSlotMin = 8 * 60;      // 8:00 AM
        let latestSlotMin = 18 * 60;      // 6:00 PM

        for (let increment of availableIncrements) {
            const dayMinuteOffset = DateConversions.dayMinuteOffset(increment.start, increment.end);

            if (dayMinuteOffset.startMin < earliestSlotMin)
                earliestSlotMin = dayMinuteOffset.startMin;
            if (dayMinuteOffset.endMin > latestSlotMin)
                latestSlotMin = dayMinuteOffset.endMin;
        }

        const earliestTimeStr =  DateConversions.minutesToTimeString(earliestSlotMin) + ":00";
        const latestTimeStr =  DateConversions.minutesToTimeString(latestSlotMin) + ":00";

        const api = this._getApi();
        if (api) {
           api.setOption('slotMinTime', earliestTimeStr);
           api.setOption('slotMaxTime', latestTimeStr);
           const height = window.innerWidth < 600 ? window.innerHeight * 0.6 : window.innerHeight * 0.70;
           api.setOption('height', height);   // give each increment 30 pixels, and add 100 pixels for the header
        }

    }


    // This function is called by the FullCalendar component to fetch events for the current view, the ref needs to exist so the component must
    // mount first. Fetch all the events within the specified time range, for the resources in the resources array, from the server
    _asyncFetchEvents = (fetchInfo, fetchEventsCallback, failureCallback) => {

        if (!this._getApi()) {       // component not mounted, no fetch
            fetchEventsCallback([]);
            return;
        }

        const start = this._fullCalendarDateToUTCJsonDateString(fetchInfo.start);
        const end = this._fullCalendarDateToUTCJsonDateString(fetchInfo.end);

        // If the range is the same as the last fetch, don't fetch again - just use the cache
        if (this._eventCache.start === start && this._eventCache.end === end) {
            console.log("Using cached events in range " + start + " to " + end);
            fetchEventsCallback(this._eventCache.events);
            return;
        }
        else
            this._eventCache = {start: null, end: null, events: []};    // clear cache

        console.log("Fetching events from " + start + " to " + end);

        // Tell the caller to fetch slots, the callback will be our fetchEventsCallback
        this.props.fetchSlots(start, end, this._service.id, 
                              (response) => this._fetchSlotsCallback(response, fetchEventsCallback, fetchInfo), 
                              (error) => failureCallback(error));
    }


    // Fetch Availability and Block slots from the server, and create clickable events for all the times that are available
    _fetchSlotsCallback = (response, fetchEventsCallback, fetchInfo) => {
        
        if (response) {

            const fetchStart = this._fullCalendarDateToLuxonDateTime(fetchInfo.start);
            const fetchEnd = this._fullCalendarDateToLuxonDateTime(fetchInfo.end);

            const slots = response.map(json => this._slotFromJson(json));
            const availablilities = slots.filter(slot => slot.type === "Availability");
            const blocks = slots.filter(slot => slot.type === "Block");

            // Create events for each available booking, for each day, if it is bookable
            const availableIncrements = [];
            let currentDay = fetchStart.startOf('day');
            const endDay = fetchEnd.startOf('day').plus({days: 1});   

            let firstAvailable = null;
            while (currentDay < endDay) {
                const currentDayEnd = currentDay.plus({days: 1});
                let incrementStart = currentDay;

                while (incrementStart < currentDayEnd) {

                    const incrementEnd = incrementStart.plus({minutes: this._service.serviceParams.incrementsMinutes});
                    if (this._timeRangeBookable(incrementStart, incrementEnd, blocks, availablilities)) {
                        if (!firstAvailable)
                            firstAvailable = incrementStart;
                        availableIncrements.push({start: incrementStart, end: incrementEnd});
                        incrementStart = incrementEnd;
                    }
                    else
                        incrementStart = incrementStart.plus({minutes: 15});  // try the next 15 minute chunk
                }
                currentDay = currentDayEnd;
            }
        
            const events = availableIncrements.map(increment => this._incrementToEvent(increment));   // convert to a fullcalendar event

            const start = this._fullCalendarDateToUTCJsonDateString(fetchInfo.start);
            const end = this._fullCalendarDateToUTCJsonDateString(fetchInfo.end);
            this._eventCache = {start: start, end: end, events: events};   // put to cache
            fetchEventsCallback(events);

            // Find the earliest and latest times that are not available, so we can limit the calendar view to show only available times
            this._setEarliestAndLatestAvailableBookings(availableIncrements);

            // Scroll to the first available date
            if (firstAvailable) {
                const api = this._getApi();
                if (api) {
                    const fullCalendarDate = firstAvailable.toISO();
                    const api = this._getApi();
                    if (api)
                        setTimeout(() => api.gotoDate(fullCalendarDate));
                }
            }

        }
        else {
            console.error("Error fetching events");
            fetchEventsCallback([]);   // No events
        }
    }

    
    // --------------------------- CALENDAR CALLBACKS FOR USER CLICKS ----------------------------

    _isMonthView = () => {
        const api = this._getApi();
        if (api)
            return api.view.type === "dayGridYear";
        return false;
    }


    _showHideClearButton = (show) => {
        const element = document.querySelector('.fc-customClear-button');
        if (element)
            element.setAttribute('style', 'display: ' + (show ? 'inline' : 'none'));
    }


    // When the user clicks on an event, we need to find the Slot object that corresponds to the event, and call the slotClickCallback. This only works for
    // real calendar events, not background events. Those are selected by dateClick
    _eventClick = (clickInfo) => {
        const eventObj = clickInfo.event;

        // see if the clicked event is already highlighted, if so don't do anything
        for (let e of this._highlightedEvents) {
            if (e.id === eventObj.id) {
                console.log("same event clicked", e.id, eventObj.id);
                this._eventClickTime = Date.now()
                return;
            }
        }

        // See if it is adjacent to any of the highlighted events, and was clicked within 1 second of the last click
        let adjacent = false;
        if (this._eventClickTime && (Date.now() - this._eventClickTime < MULTI_EVENT_CLICK_TIME)) {

            const clickedEventStart = this._fullCalendarDateToLuxonDateTime(eventObj.start);
            const clickedEventEnd = this._fullCalendarDateToLuxonDateTime(eventObj.end);

            for (let e of this._highlightedEvents) {
                const startTime = this._fullCalendarDateToLuxonDateTime(e.start);
                const endTime = this._fullCalendarDateToLuxonDateTime(e.end);
                if (endTime.valueOf() === clickedEventStart.valueOf() || startTime.valueOf() === clickedEventEnd.valueOf()) {
                    
                    // if we haven't reached the max number of increments, allow the adjacent click
                    const maxIncrements = this.props.requiredSlots ? this.props.requiredSlots : this._service.serviceParams.maxIncrements;
                    if (this._highlightedEvents.length < maxIncrements)
                        adjacent = true;
                
                    break;
                }
            }
        }
       
        // If it wasn't adjacent, clear the existing events and highlight the new one
        this._highlightEvent(eventObj, !adjacent);

        this._eventClickTime = Date.now();  // remember the time of the click
        this._showHideClearButton(true);

        // Get the earliest and latest times of the selected events
        let earliestTime = null;
        let latestTime = null;
        for (let e of this._highlightedEvents) {
            const start = this._fullCalendarDateToLuxonDateTime(e.start);
            const end = this._fullCalendarDateToLuxonDateTime(e.end);
            if (!earliestTime || start < earliestTime)
                earliestTime = start;
            if (!latestTime || end > latestTime)
                latestTime = end;
        }
        
        // Callback to set the selected time range
        this._dateSelectCallback(earliestTime, latestTime);
    }


    _todayButtonPressed = () => {
        const api = this._getApi();
        if (api)
            api.today();

        const cell = document.querySelector('.fc-day-today');
        if (cell)
            cell.scrollIntoView();
    }

    _clearButtonPressed = () => {
        this._highlightEvent(null, true);
        this._dateSelectCallback(null, null);
    }


    // -----------------------------  RENDERING  -----------------------------


    // highlight the given Event if not null, by changing the color of the event and making it more prominent, and de-highlight the previous ones if requiested
    _highlightEvent = (event, clearExisting) => {

        if (this._highlightedEvents.length > 0 && clearExisting) {      // de-highlight the previous events
            for (let e of this._highlightedEvents) {
                e.setProp('color', ThemeColors.calendarDateButton);    // restore the original color
                e.setProp('textColor', 'black');
                e.setProp('title', ''); 
            }
            this._highlightedEvents = [];
        }          
        if (!event)
            return;

        this._highlightedEvents.push(event);

         // highlight the new event
        event.setProp('color', ThemeColors.calendarDateButtonSelected);
        event.setProp('title', 'Selected');
        event.setProp('textColor', 'white');
    }


    _eventMounted = (info) => { 
        let style = info.el.getAttribute("style");
        style += "border-radius: 3px; opacity: 1.0; border: 1px solid gray; margin: 1px; ";  // make the event look like a button
        info.el.setAttribute("style", style);              // mark this so we know how to find it
    }

    render() {

        // Round the increment to the nearest hour
        const slotDurationHours = Math.ceil(this._service.serviceParams.incrementsMinutes / 60);

        // The slot duration is in minutes, so we need to convert it to a string for the FullCalendar component, with 2 digits
        const gridSlotDuration = DateConversions.minutesToTimeString(slotDurationHours * 60);

        const customButtons = { customToday: {text: 'Today', click: this._todayButtonPressed, hint: 'Go to Today'},
                                customClear: {text: 'Clear Selection', click: this._clearButtonPressed, hint: 'Clear the Selection'}
                              };      
        const leftButtons = 'prev,next customToday customClear';


        let rightButtons;
        if (this._service.serviceParams.incrementsMinutes < 24 * 60)        // only let the user see the dayGridYear view if the increment is not 24 hours
            rightButtons = 'dayGridYear,timeGridWeek,timeGridDay';


        const height = window.innerWidth < 600 ? window.innerHeight * 0.6 : window.innerHeight * 0.7;

        return (
            <FullCalendar ref={this._ref}
                            plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin, 
                                      adaptivePlugin, luxonPlugin]}
                            timeZone={this._timezone}
                            initialView='dayGridYear'
                            height={height}
                            expandRows={true}
                            nowIndicator={true}
                            monthStartFormat={{month: 'short', day: 'numeric'}}
                            slotDuration={gridSlotDuration + ':00'}
                            eventTimeFormat={{
                                hour: 'numeric',
                                minute: '2-digit',
                                meridiem: 'narrow'
                            }}
                            slotLabelFormat={{ hour: 'numeric', minute: 'numeric', merdiem: 'short' }}
                            navLinks={true}
                            allDaySlot={false}
                            headerToolbar={{
                                left: leftButtons,
                                center: 'title',
                                right: rightButtons,
                            }}      
                            buttonText={{
                                dayGridYear: 'Month',
                            }}                
                            events={{events: this._asyncFetchEvents, id: 'server'}}  
                            eventClick={this._eventClick}
                            eventDidMount={this._eventMounted}     
                            loading={this.props.loadingCallback}
                            customButtons={customButtons}
            />

        );
    }


}
