import { Global, Constructors } from '../models/Global';

export class PagedSlotList {
    
    
    pageNo;
    isLastPage;
    
    index;
    
    slots;
    
    //Construct from the backend Json Response. The type is not sent in the json, so it is passed in as a parameter
    constructor(json, type) {
        if (json) {
            this.isLastPage = json.last; 
            this.pageNo = json.pageNo; 
            this.index = json.index;

            if (json.items)
                this.slots = json.items.map(item => Constructors.slotFromJson(item, Global.getTimezone(), type));
            else
                this.slots = [];
        }  
    } 
    
    isFirst = () => {
        return this.pageNo === 0;
    }
    
    isLast = () => {
        return this.isLastPage;
    }
    
}