import React, { Component } from 'react';
import { Container, Popover, Button, TextField, Box, Typography } from '@material-ui/core'

import { Currency } from 'react-frontend-utils'


/**
 * Popover for refunding a payment. The following props are required:
 * 
 * isOpen: boolean, whether the popover is open
 * maxVal: number, the maximum amount that can be refunded
 * currency: string, the currency symbol
 * okCallback: function, called when the OK button is pressed. It takes two parameters: the refund amount and the comment
 * cancelCallback: function, called when the Cancel button is pressed
 * 
 */
export class RefundPopover extends Component {

    styles = {
        button: {
            marginLeft: 10,
            marginRight: 10
        },
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20,
            marginBottom: 10
        }
    };

 
    _okCallback;
    _cancelCallback;
    
    state = {
        amount: null,
        commentEntry: null
    }
    
    constructor(props) {
        super(props);
        this._okCallback = props.okCallback;
        this._cancelCallback = props.cancelCallback;
    }
   
    _numberFieldChanged = (event) => {
        let val;
        
        val = parseFloat(event.target.value);    
        if (val <= 0)
            val = this.props.maxVal;            
        
        if (val > this.props.maxVal)
            val = this.props.maxVal;
        
        this.setState({amount: val});
    }
    
    _commentFieldChanged = (event) => {
        let val = event.target.value;
        this.setState({commentEntry: val});
    }
    
    
    _okButtonPressed = () => {

        let val = this.state.amount;  
        if (val === null)  //never changed
            val = this.props.maxVal;
            
        const comment = this.state.commentEntry;
        
        this._okCallback(val, comment);
        this.setState({amount: null, commentEntry: null});  //clear text for next time
    }
    
    _cancelButtonPressed = () => {
        this._cancelCallback();
        this.setState({amount: null, commentEntry: null});  //clear text for next time
    }
    
    
    render() {
        
        let maxWidth = window.innerWidth * 0.5;  //half the window, up to 350
        if (maxWidth > 700)
            maxWidth = 700;
        
        
        const max = Currency.round(this.props.maxVal); 
        let val = this.state.amount === null ? max : this.state.amount;
         
        return (
            <Popover open={this.props.isOpen} 
                anchorReference="anchorPosition"
                anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
                transformOrigin={{vertical: 'center', horizontal: 'center'}}  >
                
                <Box style={{margin: 20, width: maxWidth}}>
                    <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>Refund Patron Purchase</Typography>  
                    <div style={{marginTop: 20}}/>

                    <Container style={this.styles.container}>    
                        <TextField label="Refund Amount" type="number" value={val} onChange={this._numberFieldChanged} variant="outlined" fullWidth={false} InputLabelProps={{ shrink: true}} />
                        <Typography variant="body1" style={{marginLeft: 15}}>{this.props.currency}</Typography>
                    </Container>

                    <TextField label="Reason/Comment" autoFocus multiline={true} rows={5} value={this.state.commentEntry} onChange={this._commentFieldChanged} variant="outlined" fullWidth={true} InputLabelProps={{ shrink: true}} />
        
                    <Container style={this.styles.container}>    
                        <Button onClick={this._okButtonPressed} disabled={!val} color="primary" variant="outlined" style={this.styles.button}>OK</Button>
                        <Button onClick={this._cancelButtonPressed} variant="outlined" style={this.styles.button}>Cancel</Button>
                    </Container>
                </Box>
            </Popover>
        );
        
        
    }


}