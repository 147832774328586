import { DateUtils, Permissions } from 'react-frontend-utils'
import store from 'store'

import { Resource } from './Resource'
import { Service } from './Service'
import { Availability } from './Availability'
import { Block } from './Block'
import { CalEvent } from './CalEvent'
import { Booking } from './Booking'

export const DEFAULT_TIMEZONE = "America/New_York";

/**
 * Holds the global variables for the frontend
 */
export const Global = {
    
    user: null,               //object of type User, the currently logged in user or null if none
    
    isResourceManager: () => {
        if (!Global.user)
            return false;

        return Global.user.hasPermissionTo(Permissions.MANAGE_RESOURCES); 
    },


    getDateFormat: () => {
        const fmt = store.get("dateFormat");
        if (!fmt)
            return DateUtils.DateFormatType.ISO8601;
        else
            return fmt;    
    },
    
    setDateFormat: (format) => {
        if (!DateUtils.DateFormatType.isOneOf(format)) {
            console.log("Invalid DateFormatType: " + format);
            return;
        }
        store.set("dateFormat", format);    
    },
    
    dateFormat: (date) => {
        return DateUtils.dateFormat(date, Global.getDateFormat());
    },
    
    checkInTimeFormat: (date) => {
        return DateUtils.timeFormat(date, Global.getDateFormat());
    },

    getLastDatabase: () => {
        return store.get("database");
    },
    
    setLastDatabase: (database) => {
        store.set("database", database);    
    },

    getTimezone: () => {
        return store.get("timezone");
    },

    setTimezone: (timezone) => {
        store.set("timezone", timezone);
    },
    
    getShowBackgroundSlots: () => {
        return store.get("showBackgroundSlots", true);
    },

    setShowBackgroundSlots: (show) => {
        store.set("showBackgroundSlots", show);
    },

    getShowOnlyMySlots: () => {
        return store.get("showOnlyMySlots", false);
    },

    setShowOnlyMySlots: (show) => {
        store.set("showOnlyMySlots", show);
    },

    getHideCancelledSlots: () => {
        return store.get("hideCancelledSlots", false);
    },

    setHideCancelledSlots: (hide) => {
        store.set("hideCancelledSlots", hide);
    },

    getGridSlotDuration: () => {
        return store.get("gridSlotDuration", 30);
    },

    setGridSlotDuration: (duration) => {
        store.set("gridSlotDuration", duration);
    },  
   
    
};


export const Constructors = {

    resourcefromJson: (json) => {
        if (json && json.type) {
            switch (json.type) {        // Determine which subclass this is
                case "Resource":
                    return new Resource(json);
                case "Service":
                    return new Service(json, Global.getTimezone());
                default:
                    console.error("Unknown class: " + json.type);
            }
        }
    },

    // Create a Slot subclass object from a JSON string. If the type is known a-priori, it is used to decode the object. Sometimes the object does not have the
    // "type" field populated from the server, so the knownType can be used to decode it.
    slotFromJson: (json, timezone, knownType = null) => {

        const type = knownType ? knownType : json.type;
        let slot;
        switch (type) {
            case "Availability":
                slot = new Availability(json, timezone);
                break;
            case "Block":
                slot = new Block(json, timezone);
                break;
            case "Event":
                slot = new CalEvent(json, timezone);
                break;
            case "Booking":
                slot = new Booking(json, timezone);
                break;
            default:
                console.error("Unknown slot type: " + json.type);
                return null;
        }
        slot.type = type;
        return slot;
    }


}