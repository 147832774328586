import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import queryString from 'query-string';
import ag_logo from './assets/logo.png'
import Home from './routes/Home';
import PatronBooking from './routes/PatronBooking';


//LOGO for top menu bar
export const logo = (<img src={ag_logo} alt="AGLogo" height="48" style={{background: 'white', padding: 4, borderRadius: 8}}/>);



function App() {
  return (
    <div className="App">
      <CookiesProvider>
        <Router>
            <Switch>

                <Route path='/' exact={true} render={ () => {
                    return <Redirect to="/bkportal"/>;
                }}/>
              
                     
                <Route path='/bkportal' exact={true} render={ (props) => {
                    let query = queryString.parse(props.location.search);
                    console.log("Route: /bkportal query: ", query);
                    return <Home database={query.database} slotid={query.slotid}/>;                     
                }}/>     
 
                <Route path='/patron/booking' exact={true} render={ (props) => {
                    let query = queryString.parse(props.location.search);
                    console.log("Route: /patron/booking query: ", query);
                    return <PatronBooking database={query.database} serviceid={query.serviceid} bookingid={query.bookingid}/>;                     
                }}/>
                        
            </Switch>
        </Router>
      </CookiesProvider>
    </div>
  );
}

export default App;


//Use this to send to an external website (safely!) in a new tab
export function ExternalLink(props) {
    
    return <a href={props.href} target="_blank" rel="noopener noreferrer">{props.target}</a>;
    
}


//Be careful here! XSS attack possible with url.  Use only on same site.  newWindow.opener is not cleared by this function
export function OpenInNewTab(url) {
    const newWindow = window.open(url);
    newWindow.applicationTab = window;
}


export function OpenExternalInNewTab(url) {
    window.open(url, "_blank", "noopener,noreferrer");
}