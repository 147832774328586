import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import { Typography, Button, TextField, Checkbox } from '@material-ui/core';

import { RestComponent, Currency, ManageNumericField, ManageTextField, Email, RichTextDisplay, draftContentHasText } from 'react-frontend-utils' 

import { ThemeColors } from '../Theme'
import { Global } from '../models/Global'

import PatronCheckout from '../components/PatronCheckout'


/**
 * Page 2 of the Booking process. Displays the booking details and allows the user to confirm the booking. 
 * 
 * The following props are required:
 * - service: the Service object that the user is booking on
 * - booking: the Booking object that the user is booking
 * - resource: the Resource object that the user either requested or was assigned
 * - agsServiceFee: the AGS service fee for the booking {serviceFeeMultiplier, serviceFeeFixed}
 * - prevPageCallback: a callback that returns to the previous page
 * - nextPageCallback: a callback that is called when the user has confirmed and paid for the booking
 * 
 */
export class BookServicePage2 extends RestComponent {

    styles = {
        roundedContainer: {
            border: '1px solid #CCCCCC', 
            borderRadius: '4px', 
            padding: 10,
            marginBottom: 20,
        },
        sectionLabel: {
            fontSize: 16, 
            color: 'black', 
            marginLeft: 5
        },
        membershipInstructionsLabel: {
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1,
            textAlign: 'left'
        },
        membershipField: {
            marginTop: 5,
            marginBottom: 5
        },
    }

    _prevPageCallback;
    _nextPageCallback;
    _timezone = Global.getTimezone();
    

    constructor(props) {
        super(props);
    
        this._prevPageCallback = props.prevPageCallback;  //callback that returns to BookServicePage1
        this._nextPageCallback = props.nextPageCallback;

        this.state.patronCount = 1;

        // Create an array of booleans for the optional cost items
        this.state.optionalCosts = new Array(props.service.serviceParams.additionalCosts.length).fill(false);

        this.state.patronNames = [""];
        this.state.patronEmail = "";
        this.state.tcAgree = false;

        this.state.clientSecret = null;
        this.state.membershipInfo = "";   //The membership ID or barcode typed in to identify the Membership
    }
    
    
    componentDidMount() {
        super.componentDidMount(); 
        
        // Browser back button - go back to Application List
        window.addEventListener("popstate", this._prevPageCallback);
    }
   
    componentWillUnmount() {
        super.componentWillUnmount();       
        window.removeEventListener("popstate", this._prevPageCallback);
    }

    _patronCountChanged = (json, value) => {
        this.setState({patronCount: value});

        // If the patron count changes, we need add or remove patron names
        const patronNames = [...this.state.patronNames];
        while (patronNames.length < value)
            patronNames.push("");
        while (patronNames.length > value)
            patronNames.pop();
        this.setState({patronNames: patronNames});
    }

    _setPatronEmail = (json, userValue) => {
        if (!Email.validateEmail(userValue)) {
            this.showConfirmAlert("Invalid Email Address", "Invalid email address (check for whitespaces, invalid characters, and valid domain)", 'red');
            return;
        }
        this.setState({patronEmail: userValue});
    }

    _submit = (grandTotal) => {
        const booking = this.props.booking.copy();

        // Add the optional costs to the booking as a list of strings
        booking.optionalCosts = [];
        for (let i = 0; i < this.state.optionalCosts.length; i++) {
            if (this.state.optionalCosts[i])
                booking.optionalCosts.push(this.props.service.serviceParams.additionalCosts[i].name);
        }

        booking.patronCount = this.state.patronCount;
        booking.patronNames = this.state.patronNames;
        booking.patronEmail = this.state.patronEmail;
        booking.calculatedCost = grandTotal;
        if (this.state.membershipInfo)
            booking.membershipInfo = this.state.membershipInfo;
        
        const body = booking.toJsonForPost(true);

        this.incrementBusy();
        this.secureJSONFetch("/patron/services/" + this.props.service.id + "/submitBooking", {method: 'POST', body: body}, 
                             this._submitComplete, this._fetchErrorCallback); 
    }


    _submitComplete = (response) => {
        this.decrementBusy();
        if (response)  //response is a client secret if payment required, or null if not
            this.setState({clientSecret: response, showPayment: true});
        else
            this._nextPageCallback();  // free, we're done
    }


    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }


    //Patron cancels the payment
    _returnFromPayment = () => {
        this.setState({showPayment: false, clientSecret: null});
    }


    _costItem = (cost, label, description) => {
        return (
            <div style={{display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 10, marginBottom: 10}}>
                <TextField label={label} variant="outlined" 
                        value={Currency.round(cost)} 
                        InputLabelProps={{ shrink: true}} readOnly={true}
                        InputProps={{inputProps: {style: {textAlign: 'right'} }}} 
                />
                <Typography variant="body2" style={{marginLeft: 10, fontStyle: 'italic', color: 'gray'}}>{description}</Typography>
            </div>
        );
    }

    _serviceFees = (purchaseTotal, serviceFeePercent, serviceFeeFixed, agsFees) => {

        if (purchaseTotal === 0)        //No fees for free items
            return 0;

        // These are the client's service fees
        const serviceFee1 = purchaseTotal * (serviceFeePercent / 100.0) + serviceFeeFixed;
        const totalAfterServiceFee1 = purchaseTotal + serviceFee1;

        const agsFixed = agsFees.serviceFeeFixed;
        const agsScale = agsFees.serviceFeeMultiplier;

        // Special formula that computes our fee that also includes our fee
        const agsFee = ((totalAfterServiceFee1 + agsFixed)/(1 - agsScale)) - totalAfterServiceFee1;

        return serviceFee1 + agsFee;
    }


    static patronNameLabel(index, count) {
        if (count === 1)
            return "Your Name";

        if (index === 0)
            return "Pimary Patron Name";

        return "Patron " + (index + 1) + " Name";
    }


    render() {

        const service = this.props.service;
        const sp = service.serviceParams;
        const booking = this.props.booking;

        const start = booking.startDateTimeStr();
        const end = booking.endDateTimeStr(true);

        let dateLine1Display;
        let dateLine2Display;
        if (start.dateStr === end.dateStr) {     // for same day events, just show the start date and time range
            dateLine1Display = start.dateStr;
            dateLine2Display = start.timeStr + " - " + end.timeStr;
        }
        else {      // for multi-day events, show the start and end date and time}
            dateLine1Display = "Begins: " + start.dateStr + " " + start.timeStr;
            dateLine2Display = "Ends: " + end.dateStr + " " + end.timeStr;
        }

        const msg = "Do not press your browser's back button or close this page, or you will lose your booking reservation. You have 5 minutes to complete " +
                    "the booking process. If you do not complete the booking process within 5 minutes, your booking will be cancelled and made available to " +
                    "other patrons.";

        const durationMinutes = booking.duration() / 60;
        const increments = Math.ceil(durationMinutes / sp.incrementsMinutes);
        const incrementCost = sp.costPerIncrement;
        const totalTimeCost = increments * incrementCost;
        const timeCostDescription = increments + " slot" + (increments > 1 ? "s" : "") + " x " + Currency.round(incrementCost) + " " + service.isoCurrency + " per slot";

        const patronCost = sp.costPerPatron * this.state.patronCount;
        const patronCostDescription = this.state.patronCount + " patron" + (this.state.patronCount > 1 ? "s" : "") + " x " + Currency.round(sp.costPerPatron) + " " + service.isoCurrency + " per patron";

        const totalBookingCosts = totalTimeCost + patronCost;

        const totalOptionalCosts = sp.additionalCosts.reduce((total, costItem, index) => {
            return total + (this.state.optionalCosts[index] ? costItem.cost : 0);
        }, 0);


        const subtotal = totalBookingCosts + totalOptionalCosts;

        const totalServiceFees = this._serviceFees(subtotal, sp.serviceFeePercent,
                                                    sp.serviceFeeFixed, this.props.agsServiceFee);

        const grandTotal = subtotal + totalServiceFees;

        const hasTerms = draftContentHasText(sp.termsAndConditions);

        // Check if the user can submit the booking, patron names must be filled out, email must be valid, and terms and conditions must be agreed to
        const canSubmit = this.state.patronNames.every(name => name.trim().length > 0) && 
                          Email.validateEmail(this.state.patronEmail) && 
                          (!hasTerms || this.state.tcAgree) &&
                          (!sp.requiresMembership || this.state.membershipInfo.trim().length > 0);

        let width = window.innerWidth < 916 ? '100%' : '80%';
        if (window.innerWidth > 1200)
            width = 1160 * 0.8;       // leave 40 for margins

        return (
            <Fragment>
                <div style={{display: this.state.showPayment ? 'none' : 'block', width: width, marginLeft: 'auto', marginRight: 'auto'}}>
                    {this.getConfirmAlertComponent()}

                    <Typography align='center' variant="h5" style={{marginTop: 20, marginBottom: 10}}>{service.name + ": Reserved"}</Typography>
                    <Typography align='center' variant="body2" style={{marginBottom: 10}}>{service.description}</Typography>
                    <Typography align='center' variant="h6">{dateLine1Display}</Typography>
                    <Typography align='center' variant="h6" style={{marginBottom: 10}}>{dateLine2Display}</Typography>
                    <Typography align='center' variant="body2" style={{marginBottom: 10}}>{"Timezone: " + this._timezone}</Typography>
                    <Typography align='center' variant="body2" style={{marginBottom: 10}}>{this.props.resource.name}</Typography>

                    <hr/>
                    <Typography align='center' variant="body1" style={{marginBottom: 10, color: 'red'}}>{msg}</Typography>
                    <hr/>

                    {totalBookingCosts > 0 ?
                        <Fragment>
                            <Typography variant="body2" style={this.styles.sectionLabel}>{"Booking Fees (" + service.isoCurrency + ")"}</Typography>
                            <div style={this.styles.roundedContainer}>

                                {incrementCost > 0 ? this._costItem(totalTimeCost, "Booking Time Fee", timeCostDescription) : null}

                                {sp.maxPatronCount && sp.costPerPatron > 0 ?
                                    <Fragment>
                                        <Typography variant="body2" style={{fontStyle: 'italic', marginLeft: 10, marginTop: 30}}>How many Patrons attending this Booking?</Typography>
                                        <div style={{maxWidth: 200, marginLeft: 10}}>
                                            <ManageNumericField fullWidth={false} hasInfinity={false} justify='left' 
                                                                minValue={1}
                                                                maxValue={sp.maxPatronCount}
                                                                commitTimeout={10}
                                                                json="maxPatronCount"
                                                                initialValue={1} 
                                                                onFieldChange={this._patronCountChanged} changedBackgroundColor='white'/>  
                                        </div>
                                    </Fragment>
                                    : null
                                }
                                {patronCost > 0 ? this._costItem(patronCost, "Patron Fee", patronCostDescription) : null}
                            </div>
                        </Fragment>
                        : null
                    }

                    {sp.additionalCosts.length > 0 ?
                        <Fragment>
                            <Typography variant="body2" style={this.styles.sectionLabel}>{"Add-on Optional Items"}</Typography>
                            <div style={this.styles.roundedContainer}>

                                {sp.additionalCosts.map((costItem, index) => 
                                    <Fragment>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: -5}}>
                                            <Checkbox color='primary' checked={this.state.optionalCosts[index]} 
                                                    onChange={(event) => { 
                                                            const optionalCosts = [...this.state.optionalCosts];
                                                            optionalCosts[index] = event.target.checked;
                                                            this.setState({optionalCosts: optionalCosts});
                                                    }}/>
                                            <Typography variant="body2">{costItem.name + " (" + Currency.round(costItem.cost) + " " + service.isoCurrency + ")"}</Typography>
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        </Fragment> 
                        : null
                    }

                    {totalServiceFees > 0 ?
                        <Fragment>
                            <Typography variant="body2" style={this.styles.sectionLabel}>{"Service Fees (" + service.isoCurrency + ")"}</Typography>
                            <div style={this.styles.roundedContainer}>
                                {this._costItem(totalServiceFees, "Service Fee", null)}      
                            </div>
                        </Fragment>
                        : null
                    }

                    {grandTotal > 0 ?
                        <Fragment>
                            <Typography align='center' variant="h5" style={{marginTop: 20, marginBottom: 10}}>{"Total Booking Cost: " + Currency.round(grandTotal)}</Typography>
                            <hr/>
                        </Fragment>
                        : null
                    }


                    {this.state.patronNames.map((patronName, index) =>
                        <TextField key={index} label={BookServicePage2.patronNameLabel(index, this.state.patronNames.length)} variant="outlined" 
                                value={patronName} fullWidth={true} size='small'
                                style={{marginTop: 15}}
                                InputLabelProps={{ shrink: true}}
                                    onChange={(event) => {
                                        const patronNames = [...this.state.patronNames];
                                        patronNames[index] = event.target.value;
                                        this.setState({patronNames: patronNames});
                                    }}/>
                    )}

                    <ManageTextField label="Contact Email Address"
                                    style={{marginTop: 30}}
                                    initialValue=""
                                    json="name"  
                                    autoAccept={true}
                                    onFieldChange={this._setPatronEmail}/>
                    
                    {hasTerms ?
                        <div style={{marginTop: 30}}>
                            <Typography variant="body2" style={this.styles.sectionLabel}>{"Terms and Conditions"}</Typography>
                            <div style={this.styles.roundedContainer}>
                                <RichTextDisplay draftContent={sp.termsAndConditions}/>
                                <div style={{display: 'flex', alignItems: 'center', marginBottom: -5, marginTop: 20}}>
                                    <Checkbox color='primary' checked={this.state.tcAgree} 
                                            onChange={(event) => this.setState({tcAgree: event.target.checked})}/>
                                    <Typography variant="body2">I agree to the Terms and Conditions</Typography>
                                </div>
                            </div>
                        </div>
                        : null
                    }

                    {sp.requiresMembership ?
                        <div style={{marginTop: 30}}>
                            <TextField size="small" variant="outlined" value={this.state.membershipInfo} label="Membership"
                                        onChange={(event) => {this.setState({membershipInfo: event.target.value})}} 
                                        style={this.styles.membershipField} 
                                        fullWidth={true} InputLabelProps={{ shrink: true}} />
                            <Typography variant="body2" style={this.styles.membershipInstructionsLabel}>{"A valid Membership is required. Enter your pass number, barcode number, or Membership ID above."}</Typography>  
                        </div> : null
                    }

                    <div style={{display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 40}}>
                        <Button disabled={!canSubmit} variant='contained' onClick={() => this._submit(grandTotal)} 
                                style={{backgroundColor: canSubmit ? ThemeColors.appBarBackground : ThemeColors.veryLightGray, color: 'white'}}>
                                    {grandTotal > 0 ? "Proceed to Payment" : "Confirm Booking"}
                        </Button>
                    </div>
        

                </div>
                {this.state.showPayment ? 
                    <PatronCheckout returnFromPayment={this._returnFromPayment} 
                                    paymentDescription={"Amount to Charge: " + Currency.round(grandTotal) + " " + service.isoCurrency}
                                    purchaseComplete={this._nextPageCallback}
                                    clientSecret={this.state.clientSecret}
                                    email={this.state.patronEmail}/>
                    : null
                    }
            </Fragment>
        );
    }

}

export default withCookies(withRouter(BookServicePage2));
